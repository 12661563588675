import { Injectable } from '@angular/core';
// import { LoadingController } from 'ionic-angular';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class Spinner {

  loader: any;
  constructor(private loading: LoadingController, ) {
  }

  async show(alertmessage) {
    if (!this.loader) {
      this.loader = await this.loading.create({
        spinner: 'bubbles',
        message: alertmessage,
      });
      return await this.loader.present();
    }

    setTimeout(() => {
      if (this.loader) {
        this.loader.dismiss();
        this.loader = null;
      }
    }, 35000);
  }

  showforce(alertmessage) {
    if (this.loader) {
      this.loader.dismiss();
      this.loader = null;
    }

    this.loader = this.loading.create({
      spinner: 'bubbles',
      message: alertmessage,
    });
    this.loader.present();

    setTimeout(() => {
      if (this.loader) {
        this.loader.dismiss();
        this.loader = null;
      }
    }, 35000);
  }

  hide() {
    setTimeout(() => {
      if (this.loader) {
        this.loader.dismiss();
        this.loader = null;
      }
    }, 300);
  }
}
