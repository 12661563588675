export const environment = {
    production: false,
    URL: "https://inspect.uturn.tt",
    DEBUG: "true",
    CLIENT_ID: "6",
    CLIENT_SECRET: "9ReIqtyYBdrOiiarWc7MFQr30LZF3g8p1li4XVnJ",
    examiner: {
      username: '',
      password: ''
    },
    vehicle: {
      registration_number: 'TCA205',
      chasis_number: 'MR0FZ29G307532821',
    }
  };