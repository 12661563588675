import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Spinner } from '../service/spinner/spinner';
import { AlertController, NavController } from '@ionic/angular';
import { Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "./spinner/spinner";
import * as i3 from "@angular/common/http";
var Filesystem = Plugins.Filesystem;
var toastcap = Plugins.Toast;
var GeneralService = /** @class */ (function () {
    function GeneralService(navCtrl, spinner, alertCtrl, http) {
        this.navCtrl = navCtrl;
        this.spinner = spinner;
        this.alertCtrl = alertCtrl;
        this.http = http;
        this.token = '';
        this.base = 'https://google.com';
        this.deviceAuth = false;
        this.gpson = false;
        this.windowsLocation = { "lat": 0, "lon": 0, "date": 0 };
        this.inspectionFolderIndex = 0;
        this.inspectionImageIndex = 0;
        this.geolocation = Plugins.Geolocation;
        this.generalService = this;
        var surl = this.base;
        this.base = environment.URL;
    }
    GeneralService.prototype.setToken = function (tkn) {
        this.token = tkn;
    };
    GeneralService.prototype.getToken = function () {
        return this.token;
    };
    GeneralService.prototype.getBase = function () {
        return this.base;
    };
    GeneralService.prototype.getLocatioCoor = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.geolocation.getCurrentPosition()
                .then(function (resp) {
                resolve(resp);
            }).catch(function (error) {
                reject(error);
            });
        });
    };
    GeneralService.prototype.getjson = function () {
        return this.http.get('assets/data/sampleDate.json');
    };
    GeneralService.prototype.setGarageId = function (gid) {
        this.garageId = gid;
    };
    GeneralService.prototype.getGarageId = function () {
        return this.garageId;
    };
    GeneralService.prototype.getGarageName = function () {
        return this.garageName;
    };
    GeneralService.prototype.setGarageName = function (arg0) {
        this.garageName = arg0;
    };
    GeneralService.prototype.setExaminerId = function (eid) {
        this.examinerId = eid;
    };
    GeneralService.prototype.getExaminerId = function () {
        return this.examinerId;
    };
    GeneralService.prototype.setSessionData = function (loginData) {
        //	  console.log("--ld--",loginData);
        this.sessionData = loginData;
    };
    GeneralService.prototype.setDeviceAuth = function (authStatus) {
        this.deviceAuth = authStatus;
    };
    GeneralService.prototype.getDeviceAuth = function () {
        return this.deviceAuth;
    };
    GeneralService.prototype.getSessionData = function () {
        /*	  console.log("--sd--",this.sessionData);
              console.log("---sd---",this.sessionData.data.certificate_count);
              console.log(JSON.stringify(this.sessionData));*/
        return this.sessionData;
    };
    GeneralService.prototype.setUserData = function (userdata) {
        this.userData = userdata;
    };
    GeneralService.prototype.getUserData = function () {
        return this.userData;
    };
    GeneralService.prototype.setOS = function (os) {
        this.OS = os;
    };
    GeneralService.prototype.getOS = function () {
        console.log("----os mthd----" + this.OS);
        return this.OS;
    };
    GeneralService.prototype.clearData = function () {
        this.gpson = false;
        //this.setOS(undefined);
        this.setUserData(undefined);
        this.setGarageId(undefined);
        this.setGarageName(undefined);
        this.setExaminerId(undefined);
        this.setSessionData(undefined);
    };
    GeneralService.prototype.login = function () {
        var _this = this;
        console.log('data send for login', this.userData);
        console.log('url to post', this.base + '/api/inspection/login');
        console.log('token-----***************-', this.token);
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token,
            })
        };
        this.http.post(this.base + '/api/inspection/login', this.userData, this.httpOptions)
            .subscribe(function (newdata) {
            console.log("general login", newdata);
            if (newdata.status === 'success') {
                console.log('general login', newdata);
                _this.setSessionData(newdata);
            }
            else if (newdata.status === 'Failed') {
                _this.alertCtrl.create({
                    header: 'Server Error',
                    message: 'Unable to connect to our server',
                    buttons: ['OK']
                });
                console.log('error', newdata.error);
            }
            (function (err) {
                _this.spinner.hide();
                _this.alertCtrl.create({
                    header: 'Server Error',
                    message: 'Unable to connect to our server',
                    buttons: ['OK']
                });
            });
        });
    };
    GeneralService.prototype.imageUploader = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var inspectionsPath, thisClass;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("In Image Uploader");
                        inspectionsPath = 'mowt/inspection/';
                        thisClass = this;
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                return Filesystem.readdir({
                                    path: inspectionsPath,
                                    directory: FilesystemDirectory.Documents
                                }).then(function (res) {
                                    console.log(inspectionsPath, "Inspections", res);
                                    res.files.forEach(function (value, index) {
                                        console.log(value);
                                        var inspectionPath = inspectionsPath + value + '/';
                                        var inspection_no = value;
                                        var folderIndex = index;
                                        Filesystem.readdir({
                                            path: inspectionPath,
                                            directory: FilesystemDirectory.Documents
                                        }).then(function (res) {
                                            console.log(inspectionPath, "Inspection Files", res);
                                            if (res.files.length == 0) {
                                                thisClass.deleteUploadedDirectory(inspectionPath);
                                            }
                                            if (res.files.length == 1 && res.files[0] == 'EOI') {
                                                thisClass.deleteUploadedImage(inspectionPath + 'EOI');
                                                thisClass.deleteUploadedDirectory(inspectionPath);
                                            }
                                            else {
                                                if (!(res.files.indexOf('EOI') < 0)) {
                                                    var filesCount = res.files.length;
                                                    res.files.forEach(function (value, index) {
                                                        console.log(value, index);
                                                        var inspectionImage = inspectionPath + value;
                                                        var inspectionImgName = value;
                                                        var fileIndex = index;
                                                        Filesystem.readFile({
                                                            path: inspectionImage,
                                                            directory: FilesystemDirectory.Documents,
                                                            encoding: FilesystemEncoding.UTF8
                                                        }).then(function (res) {
                                                            //console.log(inspectionFolderIndex,"-------",index);
                                                            console.log(inspectionImage, "Image Data", res);
                                                            var data = {
                                                                inspection_number: inspection_no,
                                                                name: inspectionImgName,
                                                                image: res.data
                                                            };
                                                            console.log(JSON.stringify(data));
                                                            thisClass.inspectionFolderIndex = folderIndex;
                                                            thisClass.inspectionImageIndex = fileIndex;
                                                            var lastItem = 0;
                                                            if (index == 1) {
                                                                console.log("**********************************************");
                                                            }
                                                            console.log("********************tkn**************************");
                                                            console.log('token------', thisClass.token);
                                                            thisClass.httpOptions = {
                                                                headers: new HttpHeaders({
                                                                    'Content-Type': 'application/json',
                                                                    'Authorization': 'Bearer ' + thisClass.token,
                                                                })
                                                            };
                                                            console.log(thisClass.getBase());
                                                            if (inspectionImgName != 'EOI') {
                                                                thisClass.uploadNow(data, thisClass.httpOptions, folderIndex, fileIndex, inspectionImage);
                                                            }
                                                        }).catch(function (er) {
                                                            console.log(inspectionImage, "Missing Filesystem Permission", er);
                                                        }); //upload image
                                                    });
                                                } //check if EOI is available End Of Images
                                            } //end of else
                                        }).catch(function (er) {
                                            console.log(inspectionPath, "Missing Filesystem Permission", er);
                                        }); //inspection folder
                                        //this.navCtrl.push(ChecklistPage,{progress:((index+1)/res.files.length)*100});
                                    });
                                    //this.checklist.progress = 0;
                                }).catch(function (er) {
                                    console.log(inspectionsPath, "Missing Filesystem Permission", er);
                                });
                            })];
                    case 1:
                        _a.sent(); //imageUploader
                        console.log("uploader over");
                        return [2 /*return*/];
                }
            });
        });
    };
    GeneralService.prototype.uploadNow = function (data, httpOptions, folderIndex, fileIndex, inspectionImage) {
        var _this = this;
        console.log(httpOptions);
        var thisClass = this;
        this.http.post(this.getBase() + '/api/inspection/imageupload', data, httpOptions)
            .subscribe(function (newdata) {
            console.log(newdata);
            console.log(_this.inspectionFolderIndex, "******", _this.inspectionImageIndex);
            console.log(folderIndex, "******", fileIndex);
            if (newdata.status === 'Success') {
                thisClass.deleteUploadedImage(inspectionImage);
            }
            else if (newdata.status === 'Failed') {
            }
            /*if((folderIndex==this.inspectionFolderIndex) && (fileIndex==this.inspectionImageIndex)){
                setTimeout(function(){
                    
                console.log("**** looping");
                thisClass.imageUploader();
                }, 30000);
                
                
            }*/
        }, function (err) {
            console.log('error', err);
        });
        console.log("upload over");
    };
    GeneralService.prototype.deleteUploadedImage = function (pathtofile) {
        try {
            var ret = Filesystem.deleteFile({
                path: pathtofile,
                directory: FilesystemDirectory.Documents
            });
            console.log("del file-", ret);
        }
        catch (e) {
            console.error('Unable to remove image file', e);
        }
    };
    GeneralService.prototype.deleteUploadedDirectory = function (pathtodirectory) {
        try {
            var ret = Filesystem.rmdir({
                path: pathtodirectory,
                directory: FilesystemDirectory.Documents
            });
            console.log("rm dir-", ret);
        }
        catch (e) {
            console.error('Unable to remove directory', e);
        }
    };
    GeneralService.prototype.gpsonoff1 = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    //checking if the gps validation is enabled
                    return [4 /*yield*/, this.http.get(this.getBase() + '/api/inspection/gpsonoff')
                            .subscribe(function (gpsonoff) {
                            console.log("----gps onoff mtd---" + gpsonoff.data);
                            if (gpsonoff.data == '1') {
                                _this.gpson = true;
                                return _this.gpson;
                            }
                            else {
                                _this.gpson = false;
                                return _this.gpson;
                            }
                        }, function (err) {
                            toastcap.show({
                                text: "Communication Error: " + err.status + "-" + err.statusText
                            });
                            _this.gpson = false;
                            return _this.gpson;
                        })];
                    case 1:
                        //checking if the gps validation is enabled
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GeneralService.prototype.gpsonoff = function () {
        var _this = this;
        //checking if the gps validation is enabled
        //let gpsval;
        return new Promise(function (resolve) {
            _this.http.get(_this.getBase() + '/api/inspection/gpsonoff', _this.httpOptions)
                .subscribe(function (gpsonoff) {
                console.log("----gps onoff mtd---" + gpsonoff.data);
                if (gpsonoff.data == '1') {
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            }, function (err) {
                toastcap.show({
                    text: "Communication Error: " + err.status + "-" + err.statusText
                });
                resolve(false);
            });
        });
    };
    GeneralService.prototype.getMowtGpsData = function () {
        return this.windowsLocation;
    };
    GeneralService.prototype.mowtgpsStatus = function () {
        var _this = this;
        //get windows gps location
        this.http.get('http://127.0.0.1:2464')
            .subscribe(function (wingps) {
            console.log("----wingps mtd---" + wingps);
            if (wingps == null) {
                _this.windowsLocation.lat = 0;
                _this.windowsLocation.lat = 0;
                _this.windowsLocation.date = 0;
            }
            else {
                console.log(wingps.date + "-" + wingps.lat + "-" + wingps.lon);
                if ((Math.round((new Date()).getTime() / 1000) - (wingps.date)) < 10) {
                    _this.windowsLocation.lat = wingps.lat;
                    _this.windowsLocation.lon = wingps.lon;
                    _this.windowsLocation.date = wingps.date;
                }
                else {
                    _this.windowsLocation.lat = 0;
                    _this.windowsLocation.lat = 0;
                    _this.windowsLocation.date = 0;
                }
            }
        }, function (err) {
            _this.windowsLocation.lat = 0;
            _this.windowsLocation.lat = 0;
            _this.windowsLocation.date = 0;
        });
    };
    GeneralService.prototype.getWindowsGPS = function () {
        var _this = this;
        this.spinner.show('Windows GPS Lookup');
        //get windows gps location
        this.http.get('http://127.0.0.1:2464')
            .subscribe(function (wingps) {
            console.log("----wingps mtd---" + wingps);
            if (wingps == null) {
                _this.spinner.hide();
                toastcap.show({
                    text: "No Data Available"
                });
                return _this.windowsLocation;
            }
            else {
                console.log(wingps.date + "-" + wingps.lat + "-" + wingps.lon);
                _this.spinner.hide();
                if ((Math.round((new Date()).getTime() / 1000) - (wingps.date)) < 10) {
                    _this.windowsLocation.lat = wingps.lat;
                    _this.windowsLocation.lon = wingps.lon;
                }
                return _this.windowsLocation;
            }
        }, function (err) {
            _this.spinner.hide();
            toastcap.show({
                text: "Start/Restart MOWT GPS Application"
            });
            return _this.windowsLocation;
        });
        return this.windowsLocation;
    };
    GeneralService.prototype.getWindowsGPS1 = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.spinner.show('Windows GPS Lookup');
                //get windows gps location
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http.get('http://127.0.0.1:2464')
                            .subscribe(function (wingps) {
                            console.log("----wingps mtd---" + wingps);
                            if (wingps == null) {
                                _this.spinner.hide();
                                toastcap.show({
                                    text: "No Data Available"
                                });
                                resolve(_this.windowsLocation);
                            }
                            else {
                                console.log(wingps.date + "-" + wingps.lat + "-" + wingps.lon);
                                _this.spinner.hide();
                                if ((Math.round((new Date()).getTime() / 1000) - (wingps.date)) < 10) {
                                    _this.windowsLocation.lat = wingps.lat;
                                    _this.windowsLocation.lon = wingps.lon;
                                }
                                resolve(_this.windowsLocation);
                            }
                        }, function (err) {
                            _this.spinner.hide();
                            toastcap.show({
                                text: "Start/Restart MOWT GPS Application"
                            });
                            resolve(_this.windowsLocation);
                        });
                        resolve(_this.windowsLocation);
                    })];
            });
        });
    };
    GeneralService.ngInjectableDef = i0.defineInjectable({ factory: function GeneralService_Factory() { return new GeneralService(i0.inject(i1.NavController), i0.inject(i2.Spinner), i0.inject(i1.AlertController), i0.inject(i3.HttpClient)); }, token: GeneralService, providedIn: "root" });
    return GeneralService;
}());
export { GeneralService };
