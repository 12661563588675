import { Component } from '@angular/core';
import { GeneralService } from './service/general.service';
import { Platform } from '@ionic/angular';
import { MenuController, NavController } from '@ionic/angular';
import { Router, Route } from '@angular/router';
// import { Geofence } from '@ionic-native/geofence/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { Plugins, StatusBarStyle, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';

const { Filesystem } = Plugins;
const { Device } = Plugins;


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  public appPagesExm = [
    { title: 'Dashboard', url: '/dashboard', icon: 'list' },
    { title: 'Profile', url: '/profile', icon: 'list' },
    { title: 'Stickers', url: '/inventory', icon: 'list' },
    { title: 'Inspections', url: '/inspection', icon: 'list' }
  ];
  public appPagesMowt = [
    { title: 'Dashboard', url: '/dashboard', icon: 'list' },
    { title: 'Profile', url: '/profile', icon: 'list' },
    { title: 'Fleet Requests', url: '/fleet', icon: 'list' },
    { title: 'Stickers', url: '/inventory', icon: 'list' },
    { title: 'Inspections', url: '/inspection', icon: 'list' }
  ];

  public appPages: any;
  private splashScreen = Plugins.SplashScreen;
  private statusBar = Plugins.StatusBar;
  garage_name = "No Data";
  examiner_name = "No Data";
  constructor(
    public platform: Platform,
    private router: Router,
    private menu: MenuController,
    private nav: NavController,
    public generalapi: GeneralService,
    private toast: Toast
  ) {
    this.splashScreen.show();
    this.initializeApp();
    let generalClass = this.generalapi;
    setInterval(function () {
      generalClass.imageUploader();
    }, 50000);

    Device.getInfo().then(res => {
      console.log("//////**************//////", res.osVersion);
      if (res.osVersion.toLowerCase().includes("windows")) {
        setInterval(function () {
          generalClass.mowtgpsStatus();
        }, 5000);
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.setStyle({
        style: StatusBarStyle.Dark
      });

      Filesystem.mkdir({
        path: 'mowt',
        directory: FilesystemDirectory.Documents,
        createIntermediateDirectories: true // like mkdir -p
      }).then(function () {
        //do nothing
      }, reason => {
        //do nothing
      });
      this.splashScreen.hide();
      this.router.navigate(['login']);//login

    });
  }
  logout() {
    this.generalapi.clearData();
    this.menu.close();
    console.log("v1.0 @ 12-12-2019");//Version & Date
    this.nav.navigateRoot('/login');
  }

  sessionUpdate() {
    let sessionData: any = this.generalapi.getSessionData();
    this.garage_name = sessionData.data.premises[0].proprietor.name;
    this.examiner_name = sessionData.data.examiner.name;
    console.log(sessionData.data.mowt_id + ";;;;;;;;;" + sessionData.data.premises[0].proprietor.garage_type);
    this.appPages = (sessionData.data.mowt_id == 0) ? this.appPagesExm : this.appPagesMowt;

  }

  /********************************/

  async fileWrite() {
    try {
      let ret = await Filesystem.writeFile({
        path: 'ionicinba/text.txt',
        data: "This is a test",
        directory: FilesystemDirectory.Documents,
        encoding: FilesystemEncoding.UTF8
      });
      console.log("write file-", ret);
    } catch (e) {
      console.error('Unable to write file', e);
    }
  }

  async fileRead() {
    try {
      let contents = await Filesystem.readFile({
        path: 'ionicinba/text2.txt',
        directory: FilesystemDirectory.Documents,
        encoding: FilesystemEncoding.UTF8
      }).then(res => {
        console.log("done", res);
      }).catch(async (er) => {
        console.log("error");
      });
    } catch (e) {
      console.error('Unable to make directory', e);
    }
  }

  async fileAppend() {
    try {
      let ret = await Filesystem.appendFile({
        path: 'ionicinba/text.txt',
        data: "MORE TESTS",
        directory: FilesystemDirectory.Documents,
        encoding: FilesystemEncoding.UTF8
      });
      console.log("append file-", ret);
    } catch (e) {
      console.error('Unable to make directory', e);
    }
  }

  async fileDelete() {
    try {
      let ret = await Filesystem.deleteFile({
        path: 'ionicinba/text.txt',
        directory: FilesystemDirectory.Documents
      });
      console.log("del file-", ret);
    } catch (e) {
      console.error('Unable to make directory', e);
    }
  }

  async mkdir() {
    try {
      let ret = await Filesystem.mkdir({
        path: 'ionicinba',
        directory: FilesystemDirectory.Documents,
        createIntermediateDirectories: false // like mkdir -p
      });
      console.log("mk dir-", ret);
    } catch (e) {
      console.error('Unable to make directory', e);
    }
  }

  async rmdir() {
    try {
      let ret = await Filesystem.rmdir({
        path: 'ionicinba',
        directory: FilesystemDirectory.Documents
      });
      console.log("rm dir-", ret);
    } catch (e) {
      console.error('Unable to remove directory', e);
    }
  }

  async readdir() {
    try {
      let ret = await Filesystem.readdir({
        path: 'ionicinba',
        directory: FilesystemDirectory.Documents
      });
      console.log("read dir-", ret);
      let ret1 = await Filesystem.getUri({
        path: 'ionicinba',
        directory: FilesystemDirectory.Documents,
      });

      console.log("read dir uri -", ret1);
    } catch (e) {
      console.error('Unable to read dir', e);
    }
  }

  async stat() {
    try {
      let ret = await Filesystem.stat({
        path: 'ionicinba/text.txt',
        directory: FilesystemDirectory.Documents
      });
      console.log("read stat-", ret);
    } catch (e) {
      console.error('Unable to stat file', e);
    }
  }

  async readFilePath() {
    // Here's an example of reading a file with a full file path. Use this to
    // read binary data (base64 encoded) from plugins that return File URIs, such as
    // the Camera.
    try {
      let data = await Filesystem.readFile({
        path: 'file:///var/mobile/Containers/Data/Application/22A433FD-D82D-4989-8BE6-9FC49DEA20BB/Documents/text.txt'
      })
    } catch (e) {
      console.error('Unable to read file path', e);
    }
  }

  async rename() {
    try {
      let ret = await Filesystem.rename({
        from: 'ionicinba/text.txt',
        to: 'ionicinba/text2.txt',
        directory: FilesystemDirectory.Documents
      });
      console.log("rename file-", ret);
    } catch (e) {
      console.error('Unable to rename file', e);
    }
  }


  /********************************/
}
