import { Routes } from '@angular/router';
import { PopoverPage } from './popover.page';
var routes = [
    {
        path: '',
        component: PopoverPage
    }
];
var PopoverPageModule = /** @class */ (function () {
    function PopoverPageModule() {
    }
    return PopoverPageModule;
}());
export { PopoverPageModule };
