import { OnInit } from '@angular/core';
import { PopoverController, Events } from '@ionic/angular';
import { Plugins, CameraResultType } from '@capacitor/core';
var PopoverPage = /** @class */ (function () {
    function PopoverPage(popCtrl, events) {
        this.popCtrl = popCtrl;
        this.events = events;
        this.sample = {};
        this.options = {
            quality: 100,
            allowEditing: false,
            resultType: CameraResultType.Base64,
            saveToGallery: true
        };
        this.camera = Plugins.Camera;
        var ttt = this.sample;
        this.events.subscribe("notes", function (notes) {
            ttt.notes = notes;
        });
    }
    PopoverPage.prototype.ngOnInit = function () {
    };
    PopoverPage.prototype.noteSubmit = function () {
        console.log('pop over submit');
        this.popCtrl.dismiss(this.sample);
        //this.events.publish("note", this.sample);
        console.log("----");
    };
    PopoverPage.prototype.cam = function () {
        var _this = this;
        this.camera.getPhoto(this.options).then(function (backData) {
            //const base64Image = 'data:image/jpeg;base64,' + backData;
            console.log(backData);
            _this.sample.photo = backData;
        }, function (err) {
            console.log(err);
        });
    };
    return PopoverPage;
}());
export { PopoverPage };
