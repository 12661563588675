import { Routes } from '@angular/router';
var routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'oauth', loadChildren: './oauth/oauth.module#OauthPageModule' },
    { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
    { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule' },
    { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
    { path: 'fleet', loadChildren: './fleet/fleet.module#FleetPageModule' },
    { path: 'inventory', loadChildren: './inventory/inventory.module#InventoryPageModule' },
    { path: 'inspection', loadChildren: './inspection/inspection.module#InspectionPageModule' },
    { path: 'setting', loadChildren: './setting/setting.module#SettingPageModule' },
    { path: 'help', loadChildren: './help/help.module#HelpPageModule' },
    { path: 'checklist', loadChildren: './checklist/checklist.module#ChecklistPageModule' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
