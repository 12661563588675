<ion-app>
  <!--ion-split-pane-->
    <ion-menu (ionWillOpen)="sessionUpdate()">
      <ion-header style="background-color: #3f029e;">
        <ion-grid style="height:100%; background-color: #3f029e;">
         <h3 style="margin:0; text-align:center; color:white;">{{garage_name}}</h3>
          <ion-avatar style="height:60%; width: 55%;   margin: auto;">
            <img src="assets/icon/user-default-grey.png" >
          </ion-avatar>
          <h4 style="margin:0; text-align:center; color:white;">{{examiner_name}}</h4><br>
          <h5 style="margin:0; text-align:center; color:white;">Testing Station Examiner</h5>
        </ion-grid>
      </ion-header>

      <ion-content>
        <ion-list lines="none" >
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
              <!--ion-icon slot="start" [name]="p.icon"></ion-icon-->
              <ion-label style="color: #3f029e;">
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-item (click)="logout()">
           <ion-label style="color: #3f029e;">Logout</ion-label>
          </ion-item>
		  <!--ion-item (click)="exit()">
           <ion-label style="color: #3f029e;">Exit</ion-label>
          </ion-item-->
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet main></ion-router-outlet>
  <!--/ion-split-pane-->
</ion-app>
