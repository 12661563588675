import { enableProdMode } from '@angular/core';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
/*if (environment.production) {
    console.log("prod---");
  enableProdMode();
}*/
enableProdMode();
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.log(err); });
// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
