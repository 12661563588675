import * as tslib_1 from "tslib";
import { GeneralService } from './service/general.service';
import { Platform } from '@ionic/angular';
import { MenuController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
// import { Geofence } from '@ionic-native/geofence/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { Plugins, StatusBarStyle, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
var Filesystem = Plugins.Filesystem;
var Device = Plugins.Device;
var AppComponent = /** @class */ (function () {
    function AppComponent(platform, router, menu, nav, generalapi, toast) {
        this.platform = platform;
        this.router = router;
        this.menu = menu;
        this.nav = nav;
        this.generalapi = generalapi;
        this.toast = toast;
        this.appPagesExm = [
            { title: 'Dashboard', url: '/dashboard', icon: 'list' },
            { title: 'Profile', url: '/profile', icon: 'list' },
            { title: 'Stickers', url: '/inventory', icon: 'list' },
            { title: 'Inspections', url: '/inspection', icon: 'list' }
        ];
        this.appPagesMowt = [
            { title: 'Dashboard', url: '/dashboard', icon: 'list' },
            { title: 'Profile', url: '/profile', icon: 'list' },
            { title: 'Fleet Requests', url: '/fleet', icon: 'list' },
            { title: 'Stickers', url: '/inventory', icon: 'list' },
            { title: 'Inspections', url: '/inspection', icon: 'list' }
        ];
        this.splashScreen = Plugins.SplashScreen;
        this.statusBar = Plugins.StatusBar;
        this.garage_name = "No Data";
        this.examiner_name = "No Data";
        this.splashScreen.show();
        this.initializeApp();
        var generalClass = this.generalapi;
        setInterval(function () {
            generalClass.imageUploader();
        }, 50000);
        Device.getInfo().then(function (res) {
            console.log("//////**************//////", res.osVersion);
            if (res.osVersion.toLowerCase().includes("windows")) {
                setInterval(function () {
                    generalClass.mowtgpsStatus();
                }, 5000);
            }
        });
    }
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        this.platform.ready().then(function () {
            _this.statusBar.setStyle({
                style: StatusBarStyle.Dark
            });
            Filesystem.mkdir({
                path: 'mowt',
                directory: FilesystemDirectory.Documents,
                createIntermediateDirectories: true // like mkdir -p
            }).then(function () {
                //do nothing
            }, function (reason) {
                //do nothing
            });
            _this.splashScreen.hide();
            _this.router.navigate(['login']); //login
        });
    };
    AppComponent.prototype.logout = function () {
        this.generalapi.clearData();
        this.menu.close();
        console.log("v1.0 @ 12-12-2019"); //Version & Date
        this.nav.navigateRoot('/login');
    };
    AppComponent.prototype.sessionUpdate = function () {
        var sessionData = this.generalapi.getSessionData();
        this.garage_name = sessionData.data.premises[0].proprietor.name;
        this.examiner_name = sessionData.data.examiner.name;
        console.log(sessionData.data.mowt_id + ";;;;;;;;;" + sessionData.data.premises[0].proprietor.garage_type);
        this.appPages = (sessionData.data.mowt_id == 0) ? this.appPagesExm : this.appPagesMowt;
    };
    /********************************/
    AppComponent.prototype.fileWrite = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ret, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Filesystem.writeFile({
                                path: 'ionicinba/text.txt',
                                data: "This is a test",
                                directory: FilesystemDirectory.Documents,
                                encoding: FilesystemEncoding.UTF8
                            })];
                    case 1:
                        ret = _a.sent();
                        console.log("write file-", ret);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error('Unable to write file', e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.fileRead = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contents, e_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Filesystem.readFile({
                                path: 'ionicinba/text2.txt',
                                directory: FilesystemDirectory.Documents,
                                encoding: FilesystemEncoding.UTF8
                            }).then(function (res) {
                                console.log("done", res);
                            }).catch(function (er) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    console.log("error");
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        contents = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.error('Unable to make directory', e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.fileAppend = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ret, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Filesystem.appendFile({
                                path: 'ionicinba/text.txt',
                                data: "MORE TESTS",
                                directory: FilesystemDirectory.Documents,
                                encoding: FilesystemEncoding.UTF8
                            })];
                    case 1:
                        ret = _a.sent();
                        console.log("append file-", ret);
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        console.error('Unable to make directory', e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.fileDelete = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ret, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Filesystem.deleteFile({
                                path: 'ionicinba/text.txt',
                                directory: FilesystemDirectory.Documents
                            })];
                    case 1:
                        ret = _a.sent();
                        console.log("del file-", ret);
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        console.error('Unable to make directory', e_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.mkdir = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ret, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Filesystem.mkdir({
                                path: 'ionicinba',
                                directory: FilesystemDirectory.Documents,
                                createIntermediateDirectories: false // like mkdir -p
                            })];
                    case 1:
                        ret = _a.sent();
                        console.log("mk dir-", ret);
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _a.sent();
                        console.error('Unable to make directory', e_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.rmdir = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ret, e_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Filesystem.rmdir({
                                path: 'ionicinba',
                                directory: FilesystemDirectory.Documents
                            })];
                    case 1:
                        ret = _a.sent();
                        console.log("rm dir-", ret);
                        return [3 /*break*/, 3];
                    case 2:
                        e_6 = _a.sent();
                        console.error('Unable to remove directory', e_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.readdir = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ret, ret1, e_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, Filesystem.readdir({
                                path: 'ionicinba',
                                directory: FilesystemDirectory.Documents
                            })];
                    case 1:
                        ret = _a.sent();
                        console.log("read dir-", ret);
                        return [4 /*yield*/, Filesystem.getUri({
                                path: 'ionicinba',
                                directory: FilesystemDirectory.Documents,
                            })];
                    case 2:
                        ret1 = _a.sent();
                        console.log("read dir uri -", ret1);
                        return [3 /*break*/, 4];
                    case 3:
                        e_7 = _a.sent();
                        console.error('Unable to read dir', e_7);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.stat = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ret, e_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Filesystem.stat({
                                path: 'ionicinba/text.txt',
                                directory: FilesystemDirectory.Documents
                            })];
                    case 1:
                        ret = _a.sent();
                        console.log("read stat-", ret);
                        return [3 /*break*/, 3];
                    case 2:
                        e_8 = _a.sent();
                        console.error('Unable to stat file', e_8);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.readFilePath = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, e_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Filesystem.readFile({
                                path: 'file:///var/mobile/Containers/Data/Application/22A433FD-D82D-4989-8BE6-9FC49DEA20BB/Documents/text.txt'
                            })];
                    case 1:
                        data = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_9 = _a.sent();
                        console.error('Unable to read file path', e_9);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.rename = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ret, e_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Filesystem.rename({
                                from: 'ionicinba/text.txt',
                                to: 'ionicinba/text2.txt',
                                directory: FilesystemDirectory.Documents
                            })];
                    case 1:
                        ret = _a.sent();
                        console.log("rename file-", ret);
                        return [3 /*break*/, 3];
                    case 2:
                        e_10 = _a.sent();
                        console.error('Unable to rename file', e_10);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return AppComponent;
}());
export { AppComponent };
