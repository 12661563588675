import * as tslib_1 from "tslib";
// import { LoadingController } from 'ionic-angular';
import { LoadingController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
var Spinner = /** @class */ (function () {
    function Spinner(loading) {
        this.loading = loading;
    }
    Spinner.prototype.show = function (alertmessage) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.loader) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.loading.create({
                                spinner: 'bubbles',
                                message: alertmessage,
                            })];
                    case 1:
                        _a.loader = _b.sent();
                        return [4 /*yield*/, this.loader.present()];
                    case 2: return [2 /*return*/, _b.sent()];
                    case 3:
                        setTimeout(function () {
                            if (_this.loader) {
                                _this.loader.dismiss();
                                _this.loader = null;
                            }
                        }, 35000);
                        return [2 /*return*/];
                }
            });
        });
    };
    Spinner.prototype.showforce = function (alertmessage) {
        var _this = this;
        if (this.loader) {
            this.loader.dismiss();
            this.loader = null;
        }
        this.loader = this.loading.create({
            spinner: 'bubbles',
            message: alertmessage,
        });
        this.loader.present();
        setTimeout(function () {
            if (_this.loader) {
                _this.loader.dismiss();
                _this.loader = null;
            }
        }, 35000);
    };
    Spinner.prototype.hide = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.loader) {
                _this.loader.dismiss();
                _this.loader = null;
            }
        }, 300);
    };
    Spinner.ngInjectableDef = i0.defineInjectable({ factory: function Spinner_Factory() { return new Spinner(i0.inject(i1.LoadingController)); }, token: Spinner, providedIn: "root" });
    return Spinner;
}());
export { Spinner };
