import { Component, OnInit } from '@angular/core';
import { PopoverController, Events } from '@ionic/angular';
import { Plugins, CameraResultType, CameraOptions } from '@capacitor/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.page.html',
  styleUrls: ['./popover.page.scss'],
})
export class PopoverPage implements OnInit {
  sample: any = {};
  options: CameraOptions = {
    quality: 100,
    allowEditing: false,
    resultType: CameraResultType.Base64,
	saveToGallery: true 
  };
  public camera = Plugins.Camera;
  
  constructor(
    public popCtrl: PopoverController,
	public events: Events,
  ) { 
  
	var ttt = this.sample;
 
	this.events.subscribe("notes", (notes) => {
	  
		ttt.notes=notes;
	 
	});
	  
  }

  ngOnInit() {

  }

  noteSubmit() {
    console.log('pop over submit');
    this.popCtrl.dismiss(this.sample);
	//this.events.publish("note", this.sample);
	console.log("----");
	
  }
  cam() {
    this.camera.getPhoto(this.options).then((backData) => {
      //const base64Image = 'data:image/jpeg;base64,' + backData;
      console.log(backData);
	  this.sample.photo = backData;
    }, (err) => {
      console.log(err);
    });
  }


}
