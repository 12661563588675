<ion-content>
  <ion-grid padding>
      <ion-row> 
		<ion-col size="8"><b>Notes</b> 
		</ion-col>
          <ion-col size="4">
             <ion-fab vertical="center" horizontal="center" type="Button">
                <ion-fab-button size="small">
                   <ion-icon (click)="cam()" name="camera"></ion-icon>
                </ion-fab-button>
             </ion-fab>
          </ion-col>
       </ion-row>
	   <ion-row no-padding>
		<ion-col size="12">
          <ion-textarea [(ngModel)]="sample.notes"  autosize placeholder="Add your notes here..."></ion-textarea>
		  </ion-col>
      </ion-row>
      <ion-button (click)="noteSubmit()" shape="round" expand="full">Ok</ion-button>      
  </ion-grid>
</ion-content>

