import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Spinner } from '../service/spinner/spinner';
import { AlertController, NavController } from '@ionic/angular';
import { Capacitor, Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
import { Toast } from '@ionic-native/toast/ngx';
import { environment } from 'src/environments/environment';
const { Filesystem } = Plugins;
const toastcap = Plugins.Toast;
@Injectable({
	providedIn: 'root'
})
export class GeneralService {

	token = '';
	base = 'https://google.com';
	garageId: any;
	garageName: any;
	deviceAuth: boolean = false;
	examinerId: any;
	sessionData: any;
	userData: any;
	OS: any;
	gpson = false;
	windowsLocation = { "lat": 0, "lon": 0, "date": 0 };
	generalService: GeneralService;
	inspectionFolderIndex: number = 0;
	inspectionImageIndex: number = 0;
	httpOptions: any;

	public geolocation = Plugins.Geolocation;
	constructor(
		public navCtrl: NavController,
		private spinner: Spinner,
		public alertCtrl: AlertController,
		public http: HttpClient) {
		this.generalService = this;
		let surl = this.base;

		this.base = environment.URL;

	}

	setToken(tkn) {
		this.token = tkn;
	}

	getToken() {
		return this.token;
	}

	getBase() {
		return this.base;
	}


	getLocatioCoor() {
		return new Promise((resolve, reject) => {
			this.geolocation.getCurrentPosition()
				.then((resp) => {
					resolve(resp);
				}).catch((error) => {
					reject(error);
				});
		});
	}

	getjson() {
		return this.http.get('assets/data/sampleDate.json');
	}

	setGarageId(gid) {
		this.garageId = gid;
	}

	getGarageId() {
		return this.garageId;
	}

	getGarageName(): any {
		return this.garageName;
	}
	setGarageName(arg0: any) {
		this.garageName = arg0;
	}
	setExaminerId(eid) {
		this.examinerId = eid;
	}

	getExaminerId() {
		return this.examinerId;
	}

	setSessionData(loginData) {
		//	  console.log("--ld--",loginData);
		this.sessionData = loginData;
	}

	setDeviceAuth(authStatus) {
		this.deviceAuth = authStatus;
	}

	getDeviceAuth() {
		return this.deviceAuth;
	}

	getSessionData() {
		/*	  console.log("--sd--",this.sessionData);
			  console.log("---sd---",this.sessionData.data.certificate_count);
			  console.log(JSON.stringify(this.sessionData));*/
		return this.sessionData;
	}

	setUserData(userdata) {
		this.userData = userdata;
	}

	getUserData() {
		return this.userData;
	}

	setOS(os) {
		this.OS = os;
	}

	getOS() {
		console.log("----os mthd----" + this.OS);
		return this.OS;
	}

	clearData() {
		this.gpson = false;
		//this.setOS(undefined);
		this.setUserData(undefined);
		this.setGarageId(undefined);
		this.setGarageName(undefined);
		this.setExaminerId(undefined);
		this.setSessionData(undefined);
	}

	login() {

		console.log('data send for login', this.userData);
		console.log('url to post', this.base + '/api/inspection/login');
		console.log('token-----***************-', this.token);

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.token,
			})
		};

		this.http.post(this.base + '/api/inspection/login', this.userData, this.httpOptions)
			.subscribe((newdata: any) => {
				console.log("general login", newdata);
				if (newdata.status === 'success') {
					console.log('general login', newdata);
					this.setSessionData(newdata);


				} else if (newdata.status === 'Failed') {
					this.alertCtrl.create({
						header: 'Server Error',
						message: 'Unable to connect to our server',
						buttons: ['OK']
					});
					console.log('error', newdata.error);

				}
				err => {
					this.spinner.hide();
					this.alertCtrl.create({
						header: 'Server Error',
						message: 'Unable to connect to our server',
						buttons: ['OK']
					});

				}
			});

	}


	async imageUploader() {
		console.log("In Image Uploader");
		let inspectionsPath = 'mowt/inspection/';
		let thisClass = this;
		await new Promise((resolve, reject) =>
			Filesystem.readdir({
				path: inspectionsPath,
				directory: FilesystemDirectory.Documents
			}).then((res) => {
				console.log(inspectionsPath, "Inspections", res);

				res.files.forEach(function (value, index) {
					console.log(value);
					let inspectionPath = inspectionsPath + value + '/';
					let inspection_no = value;
					let folderIndex = index;

					Filesystem.readdir({
						path: inspectionPath,
						directory: FilesystemDirectory.Documents
					}).then((res) => {
						console.log(inspectionPath, "Inspection Files", res);

						if (res.files.length == 0) {
							thisClass.deleteUploadedDirectory(inspectionPath);
						} if (res.files.length == 1 && res.files[0] == 'EOI') {
							thisClass.deleteUploadedImage(inspectionPath + 'EOI');
							thisClass.deleteUploadedDirectory(inspectionPath);
						} else {

							if (!(res.files.indexOf('EOI') < 0)) {
								let filesCount = res.files.length;
								res.files.forEach(function (value, index) {
									console.log(value, index);
									let inspectionImage = inspectionPath + value;
									let inspectionImgName = value;
									let fileIndex = index;

									Filesystem.readFile({
										path: inspectionImage,
										directory: FilesystemDirectory.Documents,
										encoding: FilesystemEncoding.UTF8
									}).then((res) => {
										//console.log(inspectionFolderIndex,"-------",index);
										console.log(inspectionImage, "Image Data", res);

										const data: any = {
											inspection_number: inspection_no,
											name: inspectionImgName,
											image: res.data
										}
										console.log(JSON.stringify(data));



										thisClass.inspectionFolderIndex = folderIndex;
										thisClass.inspectionImageIndex = fileIndex;
										let lastItem = 0;
										if (index == 1) {
											console.log("**********************************************");
										}
										console.log("********************tkn**************************");
										console.log('token------', thisClass.token);

										thisClass.httpOptions = {
											headers: new HttpHeaders({
												'Content-Type': 'application/json',
												'Authorization': 'Bearer ' + thisClass.token,
											})
										};

										console.log(thisClass.getBase());
										if (inspectionImgName != 'EOI') {
											thisClass.uploadNow(data, thisClass.httpOptions, folderIndex, fileIndex, inspectionImage);
										}

									}).catch((er) => {
										console.log(inspectionImage, "Missing Filesystem Permission", er);
									});//upload image

								});

							}//check if EOI is available End Of Images
						}//end of else
					}).catch((er) => {
						console.log(inspectionPath, "Missing Filesystem Permission", er);
					});//inspection folder

					//this.navCtrl.push(ChecklistPage,{progress:((index+1)/res.files.length)*100});
				});
				//this.checklist.progress = 0;
			}).catch((er) => {
				console.log(inspectionsPath, "Missing Filesystem Permission", er);
			}));//imageUploader

		console.log("uploader over");
	}


	uploadNow(data, httpOptions, folderIndex, fileIndex, inspectionImage) {
		console.log(httpOptions);
		let thisClass = this;
		this.http.post(this.getBase() + '/api/inspection/imageupload', data, httpOptions)
			.subscribe((newdata: any) => {
				console.log(newdata);
				console.log(this.inspectionFolderIndex, "******", this.inspectionImageIndex);
				console.log(folderIndex, "******", fileIndex);
				if (newdata.status === 'Success') {

					thisClass.deleteUploadedImage(inspectionImage);

				} else if (newdata.status === 'Failed') {

				}

				/*if((folderIndex==this.inspectionFolderIndex) && (fileIndex==this.inspectionImageIndex)){
					setTimeout(function(){
						
					console.log("**** looping");
					thisClass.imageUploader();
					}, 30000);	
					
					
				}*/

			},
				err => {
					console.log('error', err);
				}
			);

		console.log("upload over");
	}

	deleteUploadedImage(pathtofile) {
		try {
			let ret = Filesystem.deleteFile({
				path: pathtofile,
				directory: FilesystemDirectory.Documents
			});
			console.log("del file-", ret);
		} catch (e) {
			console.error('Unable to remove image file', e);
		}
	}

	deleteUploadedDirectory(pathtodirectory) {
		try {
			let ret = Filesystem.rmdir({
				path: pathtodirectory,
				directory: FilesystemDirectory.Documents
			});
			console.log("rm dir-", ret);
		} catch (e) {
			console.error('Unable to remove directory', e);
		}
	}

	async gpsonoff1() {
		//checking if the gps validation is enabled
		await this.http.get(this.getBase() + '/api/inspection/gpsonoff')
			.subscribe((gpsonoff: any) => {
				console.log("----gps onoff mtd---" + gpsonoff.data);
				if (gpsonoff.data == '1') {
					this.gpson = true;
					return this.gpson;
				} else {
					this.gpson = false;
					return this.gpson;
				}
			}, err => {
				toastcap.show({
					text: "Communication Error: " + err.status + "-" + err.statusText
				});
				this.gpson = false;
				return this.gpson;
			});
		//	return this.gpson;
	}

	gpsonoff() {
		//checking if the gps validation is enabled
		//let gpsval;
		return new Promise(resolve => {

			this.http.get(this.getBase() + '/api/inspection/gpsonoff', this.httpOptions)
				.subscribe((gpsonoff: any) => {
					console.log("----gps onoff mtd---" + gpsonoff.data);
					if (gpsonoff.data == '1') {
						resolve(true);
					} else {
						resolve(false);
					}
				},
					err => {
						toastcap.show({
							text: "Communication Error: " + err.status + "-" + err.statusText
						});
						resolve(false);
					});
		});
	}

	getMowtGpsData() {
		return this.windowsLocation;
	}

	mowtgpsStatus() {
		//get windows gps location
		this.http.get('http://127.0.0.1:2464',)
			.subscribe((wingps: any) => {
				console.log("----wingps mtd---" + wingps);
				if (wingps == null) {
					this.windowsLocation.lat = 0;
					this.windowsLocation.lat = 0;
					this.windowsLocation.date = 0;
				} else {
					console.log(wingps.date + "-" + wingps.lat + "-" + wingps.lon);
					if ((Math.round((new Date()).getTime() / 1000) - (wingps.date)) < 10) {
						this.windowsLocation.lat = wingps.lat;
						this.windowsLocation.lon = wingps.lon;
						this.windowsLocation.date = wingps.date;
					} else {
						this.windowsLocation.lat = 0;
						this.windowsLocation.lat = 0;
						this.windowsLocation.date = 0;
					}
				}
			}, err => {
				this.windowsLocation.lat = 0;
				this.windowsLocation.lat = 0;
				this.windowsLocation.date = 0;
			});
	}

	getWindowsGPS() {
		this.spinner.show('Windows GPS Lookup');
		//get windows gps location
		this.http.get('http://127.0.0.1:2464',)
			.subscribe((wingps: any) => {
				console.log("----wingps mtd---" + wingps);
				if (wingps == null) {
					this.spinner.hide();
					toastcap.show({
						text: "No Data Available"
					});
					return this.windowsLocation;
				} else {
					console.log(wingps.date + "-" + wingps.lat + "-" + wingps.lon);
					this.spinner.hide();
					if ((Math.round((new Date()).getTime() / 1000) - (wingps.date)) < 10) {
						this.windowsLocation.lat = wingps.lat;
						this.windowsLocation.lon = wingps.lon;
					}
					return this.windowsLocation;
				}
			}, err => {
				this.spinner.hide();
				toastcap.show({
					text: "Start/Restart MOWT GPS Application"
				});
				return this.windowsLocation;
			});
		return this.windowsLocation;
	}

	async getWindowsGPS1() {
		this.spinner.show('Windows GPS Lookup');
		//get windows gps location
		return new Promise(resolve => {
			this.http.get('http://127.0.0.1:2464',)
				.subscribe((wingps: any) => {
					console.log("----wingps mtd---" + wingps);
					if (wingps == null) {
						this.spinner.hide();
						toastcap.show({
							text: "No Data Available"
						});
						resolve(this.windowsLocation);
					} else {
						console.log(wingps.date + "-" + wingps.lat + "-" + wingps.lon);
						this.spinner.hide();
						if ((Math.round((new Date()).getTime() / 1000) - (wingps.date)) < 10) {
							this.windowsLocation.lat = wingps.lat;
							this.windowsLocation.lon = wingps.lon;
						}
						resolve(this.windowsLocation);
					}
				}, err => {
					this.spinner.hide();
					toastcap.show({
						text: "Start/Restart MOWT GPS Application"
					});
					resolve(this.windowsLocation);
				});
			resolve(this.windowsLocation);
		});
	}
}

